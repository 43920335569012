// src/components/Message.tsx
import React from 'react';
import { MessageDto } from "../models/MessageDto";
import ReactMarkdown from 'react-markdown'; // Add this import

interface MessageProps {
  message: MessageDto;
}

const Message: React.FC<MessageProps> = ({ message }) => {
  return (
    <div style={{ textAlign: message.isUser ? "right" : "left", margin: "8px" }}>
      <div
        style={{
          color: message.isUser ? "#ffffff" : "#ffffff",
          backgroundColor: message.isUser ? "#434f4f" : "#e30017",
          padding: "15px",
          borderRadius: "20px",
        }}
      >
        {/* Render markdown content */}
        <ReactMarkdown>{message.content}</ReactMarkdown>
      </div>
    </div>
  );
};

export default Message;
